<template>
  <div class="articleDetail_container">
    <div class="articleDetail_title_block">
      <img
        class="articleDetail_avatar"
        :src="require('@/assets/img/top.jpg')"
        alt=""
      />
      <div
        class="articleDetail_title"
        :style="`margin-right:${data.is_zh == 0 ? '' : '26px'}`"
        v-html="data.is_zh == 0 ? abstract.title : data.head_title"
      ></div>

      <div class="articleDetail_date">
        <span v-if="data.is_zh == 0"> {{ data.publish_date }}</span>
        <img
          @click="handleLike"
          :class="
            data.is_zh == 0 ? 'articleDetail_like' : 'articleDetail_like_zh'
          "
          :src="
            require(`@/assets/img/${
              countInfo?.is_collect ? 'liking' : 'like'
            }.png`)
          "
          alt=""
        />
      </div>
    </div>
    <div class="line"></div>
    <div class="articleDetail_content">
      <div class="articleDetail_row">
        <div class="articleDetail_key">
          {{ data.is_zh == 0 ? "中文标题" : "标题" }}
        </div>
        <div
          class="articleDetail_value"
          v-html="data.is_zh ? abstract.title : data.head_title"
        ></div>
      </div>
      <div
        class="articleDetail_row"
        v-if="data.is_zh == 0 && data.journal_name"
      >
        <div class="articleDetail_key">杂志名</div>
        <div class="articleDetail_value">
          《{{ data.journal_name }}》<span v-if="data.if_score"
            >（IF:{{ data.if_score }}）</span
          >
        </div>
      </div>
      <div class="articleDetail_row" v-if="data.is_zh">
        <div class="articleDetail_key">杂志名</div>
        <div class="articleDetail_value">
          <div class="periodical_math_block">
            <span v-if="data.journal_name">《{{ data.journal_name }}》</span>

            <div
              v-if="data.periodical_math?.length > 0"
              class="periodical_math"
              v-for="(item, index) in data.periodical_math.split(',')"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div class="articleDetail_row">
        <div class="articleDetail_key">文章发布日期</div>
        <div class="articleDetail_value">{{ data.original_publish_time }}</div>
      </div>
      <div class="articleDetail_row" style="margin-bottom: 5px">
        <!-- <div class="articleDetail_key">摘要</div> -->
        <div v-if="isShowAbstract()">
          <!-- 摘要：{{ this.abstract }} -->
          <span class="articleDetail_key"
            >摘要{{ !showAbstract ? ": " : "：" }}
          </span>
          <span
            class="articleDetail_content"
            v-if="typeof abstract.content == 'string'"
          >
            {{ abstract.content }}
          </span>
        </div>

        <div v-if="typeof abstract.content == 'object'">
          <div v-for="item in abstract.content" :key="item[0]">
            <span style="font-weight: 600; color: #000"
              >{{ item[0] }}{{ !this.showAbstract ? ": " : "：" }}
            </span>
            <span class="articleDetail_content">{{ item[1] }}</span>
          </div>
        </div>
        <!-- <div
          class="articleDetail_value"
          style="color: #5f5f5f; line-height: 29px; white-space: pre-line"
        >
          {{ abstract.content }}
        </div> -->
      </div>
    </div>
    <div class="line"></div>
    <div class="articleDetail_button_group">
      <CustomButton
        class="custom_button"
        @click="clickJump()"
        :class="data.literature_url ? 'custom_button_active' : ''"
      >
        <img
          class="articleDetail_button_icon"
          :src="
            require(`@/assets/img/${
              data.literature_url ? 'clickJumping' : 'clickJump'
            }.png`)
          "
          alt=""
        />点击跳转</CustomButton
      >
      <CustomButton
        class="custom_button"
        @click="originalHelp()"
        :class="data.literature_url ? 'custom_button_active' : ''"
        ><img
          class="articleDetail_button_icon"
          :src="
            require(`@/assets/img/${
              !data.literature_url ? 'originalHelp' : 'originalHelping'
            }.png`)
          "
          alt=""
        />原文求助</CustomButton
      >
    </div>
    <div
      class="articleDetail_button_group"
      style="margin-top: 0"
      :style="`justify-content: ${
        !data.en_content ? 'center' : 'space-between'
      }`"
    >
      <CustomButton
        v-if="data.en_content"
        class="custom_button"
        @click="translateAb()"
        :class="showAbstract ? 'custom_button_active' : ''"
        ><img
          class="articleDetail_button_icon"
          :src="require(`@/assets/img/${showAbstract ? 'AIing' : 'AI'}.png`)"
          alt=""
        />{{
          !data.en_content
            ? "AI自动翻译"
            : !show_model
            ? "AI自动翻译"
            : "取消自动翻译"
        }}
      </CustomButton>
      <CustomButton class="custom_button custom_button_active" @click="goHome()"
        ><img
          class="articleDetail_button_icon"
          :src="require('@/assets/img/homing.png')"
          alt=""
        />周刊首页</CustomButton
      >
    </div>

    <popup :msg="popMsg" v-if="showPopup" @doClose="doClose" @doSure="doSure">
      <div class="powerpopup_main">
        <div class="powerpopup_main_title">
          本平台不提供任何原文资源，仅采用百度学术文献互助平台的C2C模式，业内同道接到您的需求后会分享您的邮箱。
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">姓名：</div>
          <div class="powerpopup_row_value">
            <el-input
              v-model.trim="helpData.name"
              type="text"
              placeholder="请输入姓名"
            />
          </div>
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">省份：</div>
          <div class="powerpopup_row_value">
            <el-select
              v-model.trim="helpData.province"
              placeholder="请选择省份"
              @change="changeProvince()"
            >
              <el-option
                v-for="(item, index) in provinces"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">城市：</div>
          <div class="powerpopup_row_value">
            <el-select
              v-model.trim="helpData.city"
              placeholder="请选择城市"
              @change="changeCity"
            >
              <el-option
                v-for="(item, index) in citys"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">医院：</div>
          <div class="powerpopup_row_value">
            <el-input
              v-model.trim="helpData.hospital"
              type="text"
              placeholder="请输入医院"
            />
          </div>
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">科室：</div>
          <div class="powerpopup_row_value">
            <el-input
              v-model.trim="helpData.department"
              type="text"
              placeholder="请输入科室"
            />
          </div>
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">手机号：</div>
          <div class="powerpopup_row_value">
            <el-input
              v-model.trim="helpData.phone"
              type="text"
              placeholder="请输入手机号"
            />
          </div>
        </div>
        <div class="powerpopup_row">
          <div class="powerpopup_row_key">邮箱：</div>
          <div class="powerpopup_row_value">
            <el-input
              v-model.trim="helpData.email"
              type="text"
              placeholder="请输入邮箱"
            />
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import CustomButton from "@/components/unit/CustomButton";
import Popup from "../../unit/Popup.vue";
import weixin from "@/common/weixin";
import mixins from "@/components/unit/mixins";
export default {
  mixins: [mixins],
  name: "articleDetail",
  components: {
    CustomButton,
    Popup,
  },
  data() {
    return {
      popMsg: {
        align: "center",
        title: "原文求助",
        btngroup: { sure: "确认", close: "返回" },
      },
      showPopup: false,
      data: {},
      showAbstract: false,
      abstract: { title: "", content: "" },
      countInfo: {},
      show_model: 0, //show_model  1是中文 0是英文
      config: { citys: [] },
      helpData: {
        name: "",
        phone: "",
        province: "",
        city: "",
        hospital: "",
        department: "",
        email: "",
      },
      literature_type: 0,
      id: "",
    };
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.id = this.$route.query.id;
    this.literature_type = this.$route.query.literature_type || 0;
    this.initCountInfo();
    this.initData();
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.helpData.province) return [];
      let index = this.provinces.indexOf(this.helpData.province);
      if (index == -1) return [];
      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  methods: {
    initShare() {
      let params = {
        title: this.data.head_title,
        description: "",
        url: "",
        logo: this.$config.logo,
      };
      console.log("params :>> ", params);
      weixin.sharePageMessage(params);
    },
    changeProvince() {
      this.helpData.city = "";
      this.helpData.hospital = "";
    },
    changeCity() {},
    initData() {
      let url = this.$tools.getURL(this.$urls.literature.detail, {
        id: this.id,
        literature_type: this.literature_type,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          this.showAbstract = this.data.en_content ? true : false;
          this.show_model = this.data.en_content == "" ? 1 : 0;
          if (this.show_model) {
            this.abstract.title = this.data.zh_title;
            this.abstract.content = this.data.zh_content;
          } else {
            this.abstract.title = this.data.en_title;
            this.abstract.content = this.data.en_content;
          }
          setTimeout(() => {
            this.initShare();
          }, 1000);
          console.log("this.abstract :>> ", this.abstract);
        })
        .catch(() => {});
    },
    initCountInfo() {
      let url = this.$tools.getURL(this.$urls.literature.countInfo, {
        type: this.$store.state.common.userInfo.id
          ? 0
          : this.$store.state.common.salesInfo.phone
          ? 1
          : 0,
        id: this.id,
        literature_type: this.literature_type,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.countInfo = res.data;
        })
        .catch(() => {});
    },
    handleLike() {
      if (
        this.$store.state.common.userInfo.id ||
        this.$store.state.common.salesInfo.phone
      ) {
        let url = this.$tools.getURL(this.$urls.literature.collect, {
          id: this.data.id,
          type: this.$store.state.common.userInfo.id
            ? 0
            : this.$store.state.common.salesInfo.phone
            ? 1
            : 0,
        });
        this.$axios
          .get(url)
          .then((res) => {
            console.log("res :>> ", res);
            this.$tips.success({ text: res.msg });
            this.countInfo.is_collect = this.countInfo.is_collect ? 0 : 1;
          })
          .catch(() => {});
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.data.id,
          },
        });
      }
    },
    clickJump() {
      if (!this.data.literature_url)
        return this.$tips.error({ text: "暂无原文链接" });
      //游客跳专家  管理员直接求助
      let userLogin = this.$tools.getStorage("userLogin", localStorage);
      let saleLogin = this.$tools.getStorage("saleLogin", localStorage);
      if (userLogin?.phone || saleLogin?.phone) {
        this.doOperationRecord(this.data.id, 0);
        this.$tips.success({ text: "正在跳转中，请稍后" });
        window.location.href = this.data.literature_url;
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.data.id,
          },
        });
      }
    },
    goHome() {
      this.doOperationRecord(this.data.id, 2);
      this.$router.push({
        path: "/articleList",
        query: {
          periods: this.data.periods,
        },
      });
    },
    translateAb() {
      //摘要
      if (!this.showAbstract) return this.$tips.error({ text: "暂无英文翻译" });
      this.show_model = !this.show_model;
      if (this.show_model) {
        this.doOperationRecord(this.data.id, 1);
        this.abstract.title = this.data.zh_title;
        this.abstract.content = this.data.zh_content;
      } else {
        this.abstract.title = this.data.en_title;
        this.abstract.content = this.data.en_content;
      }
    },
    getAbstract() {
      if (/\[\[/.test(this.data.zh_content)) {
        try {
          let abstract = eval(this.data.zh_content);
          if (abstract[0]?.length == 1) {
            this.abstract.content = abstract[0][0];
          } else {
            this.abstract.content = abstract;
          }
        } catch (err) {
          this.abstract.content = this.data.zh_content;
        }
      } else {
        this.abstract.content = this.data.zh_content;
      }
      return this.abstract.content;
    },
    //双摘要问题
    isShowAbstract() {
      return !(
        typeof this.abstract.content == "object" &&
        this.abstract.content
          .map((x) => x[0])
          .flat()
          .includes("摘要")
      );
    },
    originalHelp() {
      // if (!this.data.literature_pdf_url)
      //   return this.$tips.error({ text: "原文在路上" });
      //游客跳专家  管理员直接求助
      let userLogin = this.$tools.getStorage("userLogin", localStorage);
      let saleLogin = this.$tools.getStorage("saleLogin", localStorage);
      if (!userLogin?.phone && !saleLogin?.phone) {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.data.id,
          },
        });
      } else {
        this.showPopup = true;
        this.helpData =
          this.$tools.getStorage(
            `helpData-${this.$config.project}`,
            localStorage
          ) || {};
      }
    },
    doSure() {
      if (!this.checkData()) return;
      let loading = this.$tips.loading();

      this.$axios
        .post(this.$urls.courseware.originalHelp, {
          ...this.helpData,
          id: this.data.id,
        })
        .then(() => {
          this.$tips.success({ text: "求助成功" });
          this.$tools.setStorage(
            `helpData-${this.$config.project}`,
            this.helpData,
            localStorage
          );
          loading.close();
          this.doClose();
        })
        .catch(() => {
          setTimeout(() => {
            loading.close();
          }, 1000);
        });
    },
    checkData() {
      if (!this.helpData.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.helpData.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1\d{10}$/g.test(this.helpData.phone)) {
        this.$tips.error({ text: "请输入格式正确的手机号" });
        return false;
      }

      if (!this.helpData.province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.helpData.city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.helpData.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.helpData.department) {
        this.$tips.error({ text: "请输入专家科室" });
        return false;
      }
      if (!this.helpData.email) {
        this.$tips.error({ text: "请输入邮箱" });
        return false;
      }
      if (!/^\w+[.-\w]+@[-\w]+\.\w+$/.test(this.helpData.email)) {
        this.$tips.error({ text: "邮箱格式不正确！" });
        return false;
      }

      return true;
    },
    doClose() {
      this.showPopup = false;
    },
  },
};
</script>

<style>
.articleDetail_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 22px;
  display: flex;
  flex-direction: column;
}
.articleDetail_title_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.articleDetail_avatar {
  width: 33px;
  height: 33px;
}

.articleDetail_title {
  font-weight: bold;
  font-size: 15px;
  color: #000000;
  line-height: 35px;
  text-align: center;
}
.articleDetail_date {
  width: 100%;
  font-weight: bold;
  font-size: 11px;
  color: #000000;
  line-height: 22px;
  display: flex;
  justify-content: center;
  position: relative;
  justify-content: center;
}
.articleDetail_like {
  width: 20px;
  position: absolute;
  right: 0;
}
.articleDetail_like_zh {
  width: 20px;
  position: absolute;
  right: 0;
  bottom: 9px;
}
.articleDetail_content {
  /* margin-top: 10px; */
  /* border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc; */
  padding-top: 16px;
  flex: 1 0 0;
  overflow: auto;
}
.articleDetail_row {
  margin-bottom: 8px;
}
.articleDetail_key {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}
.articleDetail_value {
  font-size: 13px;
  color: #000000;
  line-height: 35px;
}
.articleDetail_content {
  font-size: 13px;
  color: #5f5f5f;
  line-height: 35px;
  white-space: pre-line;
}
.articleDetail_button_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 28px;
}
.articleDetail_button_group:last-child {
  margin-top: 0 !important;
}
.articleDetail_button_group .custom_button {
  width: 45%;
  background: #fff;
  border: 1px solid #a6a6a6;
  color: #a6a6a6;
  border-radius: 4px !important;
  margin-bottom: 10px;
}
.articleDetail_button_group .custom_button_active {
  background: #fff;
  border: 1px solid #1aa6ec;
  color: #1aa6ec;
}

.articleDetail_button_icon {
  width: 17px;
  margin-right: 6px;
}

.articleDetail_container .popup_main {
  padding: 16px;
}
.articleDetail_container .powerpopup_main_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #606060;
  text-align: center;
  margin: 16px 0;
}
.articleDetail_container .powerpopup_row {
  display: flex;
  align-items: center;

  font-size: 14px;
  color: #606060;
  justify-content: center;
  line-height: 40px;
}
.articleDetail_container .powerpopup_row_key {
  width: 60px;
  text-align-last: justify;
  font-weight: bold;
}
.articleDetail_container .powerpopup_row_value {
  position: relative;
  height: 40px;
  width: 180px;
}
.articleDetail_container .powerpopup_row_value el-input {
  /* position: absolute;
  top: 0; */
}
.articleDetail_container .powerpopup_row_value el-select {
  /* position: absolute;
  top: 0; */
}
.articleDetail_container .powerpopup_row .el-input__inner {
  width: 180px;
  border: none;
}
.articleDetail_container .powerpopup_tips {
  font-size: 12px;
  color: #1aa6ec;
  line-height: 26px;
}
.articleDetail_container .popup_btn_group {
  margin-top: 10px;
}

.articleDetail_container .popup_btn_group .customButton_container {
  width: 104px;
  height: 31px;
  margin-bottom: 16px;
  border-radius: 3px;
}
.line {
  width: 100%;
  border: 0.1px solid #ccc;
}
.periodical_math_block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
  min-height: 35px;
}
.periodical_math {
  height: 20px;

  border: 1px solid #009d7d;
  padding: 2px 6px;
  border-radius: 6px;
  margin: 7.5px 0;
  margin-right: 6px;
  /* margin-bottom: 10px; */
}
</style>